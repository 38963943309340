import request from '@/utils/request'
// 获取用户信息
export function getInfo(params) {
    return request({
        url: '/api/h5_customer',
        method: 'get',
        params
    })
}
// 登录
export function login(data) {
    return request({
        url: 'api/h5_login',
        method: 'post',
        data
    })
}
// 登出
export function logout() {
    return request({
        url: '/api/logout',
        method: 'post'
    })
}
// 获取验证码
export function verifyCode(params) {
    return request({
        url: '/api/verifyCode',
        method: 'GET',
        params
    })
}
// 客户-公众号授权登陆
export function wxLogin(data) {
    return request({
        url: '/api/customer/wx_login',
        method: 'post',
        data
    })
}
// 客户-获取认证校验信息
export function customerInfo(params) {
    return request({
        url: '/api/customer/auth_check/info',
        method: 'GET',
        params
    })
}
// 客户-授权认证
export function wxAuth(data) {
    return request({
        url: '/api/customer/wx_auth',
        method: 'post',
        data
    })
}

