<template>
  <div class="App_main">
    <router-view :key="key" />
  </div>

</template>

<script>
export default {
  name: "AppMain",
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style scoped lang="scss">
.App_main{
  width: 100%;
  min-height: 100vh;
  background-color: #FBFBFB;
}
</style>
