import {logout, getInfo, wxLogin} from '@/api/user'
import {getToken, removeToken} from '@/utils/auth'
import storage from 'good-storage'

const getDefaultState = () => {
    return {
        token: getToken(),
        name: '',
        phone: '',
        nick: '',
        status: '',
        prefix: '',
        type: '',
    }
}
const state = getDefaultState()
const mutations = {
    RESET_STATE: (state) => {
        Object.assign(state, getDefaultState())
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_PHONE: (state, phone) => {
        state.phone = phone
    },
    SET_AVATAR: (state, nick) => {
        state.nick = nick
    },
    SET_STATE: (state, status) => {
        state.status = status
    },
    SET_PREFIX: (state, prefix) => {
        state.prefix = prefix
    },
    SET_TYPE: (state, type) => {
        state.type = type
    },
}
const actions = {
    // 验证码登录
    login({ commit },userInfo) {
        const {phone, prefix, verifyCode, send_location, id, s} = userInfo
        return new Promise((resolve, reject) => {
            wxLogin({
                prefix: prefix,
                verifyCode: verifyCode,
                phone: phone.trim(),
                id: id,
                send_location: send_location,
                s: s
            }).then(response => {
                storage.set('userId', response.data.id)
                commit('SET_STATE', response.data.auth_status)
                resolve(response.data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // 获取用户信息
    getInfo({commit}) {
        return new Promise((resolve, reject) => {
            const id = storage.get('userId')
            getInfo({id: id}).then(response => {
                const data = response.data
                commit('SET_AVATAR', data.headimgurl)
                commit('SET_NAME', data.name)
                commit('SET_STATE', data.auth_status)
                commit('SET_PHONE', data.phone)
                commit('SET_PREFIX', data.prefix)
                commit('SET_TYPE', data.type)
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // user logout
    logout({commit, state}) {
        return new Promise((resolve, reject) => {
            logout(state.token).then(() => {
                removeToken() // must remove  token  first
                // resetRouter()
                commit('RESET_STATE')
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },
    resetToken({commit}) {
        return new Promise(resolve => {
            storage.remove('userId') // must remove  token  first
            storage.remove('AuditStatus') // must remove  token  first
            storage.remove('GoHome') // must remove  token  first
            commit('RESET_STATE')
            resolve()
        })
    }
}
export default {
    namespaced: true,
    state,
    mutations,
    actions
}
