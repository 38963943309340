import axios from 'axios'
import { Notify} from 'vant';
import store from '@/store'
// import storage from "good-storage";

// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
    // baseURL: '',
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // 请求超时时间
})

// 请求拦截器
service.interceptors.request.use(function(config) {
    // config.headers.common['auth_type'] = 1
    return config
}, function(error) {
    return Promise.reject(error)
})
// 响应拦截器
service.interceptors.response.use(
    /**
     *如果您想获取http信息，如标头或状态
     *请返回回复=>回复
     */

    /**
     *通过自定义代码确定请求状态
     *这里只是一个例子
     *您还可以通过HTTP状态码来判断状态
     */
    response => {
        const res = response.data
        if (res.code !== '100000' && res.code !== 200 && res.code !== '100200') {
            Notify({ message: res.msg || 'Error',   duration: 1500 })
            alert(res.msg || 'Error')
            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if (res.code === '100100') {
                store.dispatch('user/resetToken').then(() => {
                    location.reload()
                })
            }
            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug

        // alert(error + '+++++')

        // Notify({type: 'danger', message: error.msg,duration: 5 * 1000});
        return Promise.reject(error)
    }
)

export default service
