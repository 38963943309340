const getters = {
  token: state => state.user.token,
  name: state => state.user.name,
  phone: state => state.user.phone,
  nick: state => state.user.nick,
  status: state => state.user.status,
  prefix: state => state.user.prefix,
  type: state => state.user.type,
  listCode: state => state.public.listCode,
}
export default getters
