import router from './router'
import store from './store'
import storage from "good-storage";

const whiteList = ['/login','/empowerIndex']

router.beforeEach(async (to, from, next) => {

    const hasToken = storage.get('userId')

    if (to.meta.title) {
        document.title = `云启 - ${to.meta.title}`
    } else {
        document.title = '云启'
    }

    if (hasToken) {
        if (to.path === '/login') {
            next({path: '/'})
        } else {
            const info = await store.dispatch('user/getInfo')
            if (to.path !== '/authentication' && info.auth_status !== '2') {
                next(`/authentication`)
            } else {
                next()
            }
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next(`/login`)
        }
    }
})
