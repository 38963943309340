import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import Layout from '@/layout'
const routes = [

  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [{
      path: 'home',
      name: 'home',
      component: () => import('@/views/home/home'),
      meta: { title: '首页'}
    },{
      path: 'file',
      name: 'file',
      component: () => import('@/views/home/file'),
      meta: { title: '文件'}
    },{
      path: 'my',
      name: 'my',
      component: () => import('@/views/home/my'),
      meta: { title: '我的'}
    }]
  },
  {
    path: '/setting',
    component: Layout,
    redirect: '/setting',
    children: [{
      path: 'setting',
      name: 'setting',
      component: () => import('@/views/home/setting'),
      meta: { title: '设置'}
    },]
  },
  {
    path: '/authentication',
    name: 'authentication',
    component: () => import('@/views/authentication/index'),
    meta: { title: '认证'}
  },
  {
    path: '/openPdf',
    name: 'openPdf',
    component: () => import('@/views/home/openPdf'),
    meta: { title: 'pdf'}
  },
  {
    path: '/login',
    name: 'login',
    component: () => import( '../views/login/index'),
    meta: { title: '登录'}
  },
  {
    path: '/empowerIndex',
    name: 'empowerIndex',
    component: () => import( '../views/login/empowerIndex'),
    meta: { title: '授权'}
  }
]

const router = new VueRouter({
  routes,
  // mode: "history"
})

export default router
