<template>
  <div class="app_main">
    <appMain></appMain>
    <van-tabbar v-model="active" @change="onChange">
      <van-tabbar-item name="home">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active || active ==='home' ? home.active : home.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item name="file">
        <span>文件</span>
        <template #icon="props">
          <img :src="props.active || active ==='file' ? file.active : file.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="friends-o" name="my">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active || active ==='my' ||active ==='setting'   ? my.active : my.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import appMain from "@/layout/comoonents/AppMain";
export default {
  name: "index",
  components:{
    appMain,
    // navbar
  },
  data() {
    return {
      home: {
        active: require('../assets/images/home_b.png'),
        inactive: require('../assets/images/home_c.png'),
      },
      file: {
        active: require('../assets/images/file_b.png'),
        inactive: require('../assets/images/file_icon_ash.svg'),
      },
      my: {
        active: require('../assets/images/my_b.png'),
        inactive: require('../assets/images/my_c.png'),
      },
    };
  },
  computed:{
    active:{
      get() {
        return this.$route.name
      },
      set(newValue) {
        return newValue;
      },

    }
  },
  methods: {
    onChange(index) {
      this.active = index
      this.$router.push({name: index})
    },
  },
}
</script>

<style scoped lang="scss">
.app_main{
  width: 100vw;
  min-height: 100vh;
  background-color: #FBFBFB;
}
</style>
